import React from "react";

const TestPage = () => {
  return (
    <div style={{ backgroundColor: "white", height: "500px", width: "500px" }}>
      TEST PAGE FOR MODAL
    </div>
  );
};

export default TestPage;
