import "./server-css/Server.css";
import ServerList from "./ServerList";

function Servers() {
  return (
    <div>
      <ServerList />
    </div>
  );
}

export default Servers;
